import React from "react"
import {Button as AntButton} from "antd"
import {ButtonProps} from "antd/lib/button"

/**
 * @param props
 * @returns {JSX.Element}
 */
export default function Button(props: ButtonProps) {
    return (
        <AntButton {...props} className={props.className}>
            {props.children}
        </AntButton>
    )
}
