import styled from "styled-components"

// Component
import Button from "./Button"

// Models
import {StyledProps} from "../../models"

export default styled(Button)`
  background-color: transparent;
  border-color: ${(props: StyledProps) => props.theme.color.blue};
  border-radius: 25px;
  color: ${(props: StyledProps) => props.theme.color.blue};
  height: 50px;
  font-size: 16px;
  font-weight: ${(props: StyledProps) => props.theme.fontWeight.bold};
  
  &.ant-btn:hover {
    background-color: transparent;
    border-color: ${(props: StyledProps) => props.theme.color.blue};
    color: ${(props: StyledProps) => props.theme.color.blue};
    opacity: .85;
  }
  
  &.ant-btn-primary, &.ant-btn-primary:hover {
    background-color: ${(props: StyledProps) => props.theme.color.blue};
    box-shadow: 0 10px 20px -10px #587ff3;
    color: ${(props: StyledProps) => props.theme.color.white};
  }
  
  &.ant-btn-primary[disabled], &.ant-btn[disabled]:hover {
    background-color: #e6e6eb;
    border: none;
    box-shadow: none;
    color: ${(props: StyledProps) => props.theme.color.white};
    opacity: 1;
  }
` as any
